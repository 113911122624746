import React from "react";
import FallingLines from "@/components/Common/ContentBox/FallingLines";
import FloatingCircles from "@/components/Common/ContentBox/FloatingCircles";

const ContentBox = ({
  children,
  fallingLines = false,
  floatingCircles = false,
  backgroundColor = null,
  backgroundGradient = null,
  backgroundImageObject = null,
  height = "auto",
  maxBodyWidth = "1000px",
  innerBoxProps = {},
  innerBoxClassName = "",
  className = "",
  ...restProps
}) => {
  return (
    <section>
      <div
        className={`content-box flex flex-wrap justify-center w-full relative z-10 overflow-hidden mx-0 ${className}`}
        style={{
          backgroundColor: backgroundColor,
          height: height,
        }}
        {...restProps}
      >
        {fallingLines && <FallingLines />}
        {floatingCircles && <FloatingCircles />}
        <div
          className={`p-4 w-full relative z-20 ${innerBoxClassName}`}
          style={{
            maxWidth: maxBodyWidth,
          }}
          {...innerBoxProps}
        >
          {children}
        </div>
      </div>
    </section>
  );
};

export default ContentBox;
