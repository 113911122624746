import React from "react";
import { Typography, useMediaQuery } from "@mui/material";
import {
  motion,
  useScroll,
  useTransform,
  cubicBezier,
  useSpring,
} from "framer-motion";
import { SvgIcon } from "@mui/material";

export const SlidingWithScroll = ({ chooseALanguage }) => {
  const isXs = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const isSm = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const isMd = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const isLg = useMediaQuery((theme) => theme.breakpoints.down("xl"));
  const isXl = useMediaQuery((theme) => theme.breakpoints.up("xl"));
  const ease = { ease: cubicBezier(0.14, 0.4, 0.09, 0.99) };
  const springConfig = { damping: 100, mass: 10, stiffness: 100 }; //default { damping: 10, mass: 1, stiffness: 100 }

  const { scrollYProgress } = useScroll({
    target: chooseALanguage,
    offset: [isXs ? "start 80%" : "start 50%", "start start"],
  });
  const springYProgress = useSpring(scrollYProgress, springConfig);
  const effects = {
    chooseALanguage: {
      x: useTransform(
        springYProgress,
        [0, 1],
        ["0vw", isXs ? "8vw" : "5vw"]
        // ease
      ),
      opacity: useTransform(
        scrollYProgress,
        [isXs ? 0.7 : 0.5, 1],
        [0.5, 0],
        ease
      ),
    },
    chooseALearningPath: {
      x: useTransform(
        springYProgress,
        [0, 0.9],
        ["0vw", isXs ? "-5vw" : "-5vw"]
        // ease
      ),
      opacity: useTransform(
        springYProgress,
        [0, 0.9],
        [0.5, 0.85]
        // ease
      ),
    },
    or: {
      opacity: useTransform(
        scrollYProgress,
        [isXs ? 0.8 : 0.5, 1],
        [1, 0],
        ease
      ),
    },
    orUp: {
      y: useTransform(
        springYProgress,
        [0, isXs ? 1 : 0.5],
        ["0px", "-20px"],
        ease
      ),
    },
    orDown: {
      y: useTransform(
        springYProgress,
        [0, isXs ? 1 : 0.5],
        ["0px", isXs ? "10px" : "20px"],
        ease
      ),
    },
  };

  return (
    <div
      className="flex items-center justify-center w-full h-0 relative pointer-events-none"
      ref={chooseALanguage}
    >
      <Typography
        className="absolute left-auto right-auto z-40 text-center opacity-50 text-white will-change-transform"
        variant="h1"
        component={motion.p}
        fontSize={{
          xs: "28px",
          sm: "48px",
          md: "72px",
          lg: "96px",
          xl: "120px",
        }}
        sx={{
          bottom: {
            xs: "-7px",
            sm: "-12px",
            md: "-18px",
            lg: "-24px",
            xl: "-30px",
          },
        }}
        style={{
          x: effects.chooseALanguage.x,
          opacity: effects.chooseALanguage.opacity,
        }}
      >
        Choose a Language
      </Typography>
      <motion.div
        className="flex flex-row items-center justify-center -mt-8 z-50 absolute top-8 lg:top-9 xl:top-12 left-0 right-0"
        style={{
          opacity: effects.or.opacity,
        }}
      >
        <SvgIcon
          component={motion.svg}
          className="will-change-transform"
          color="secondary"
          style={{
            y: effects.orUp.y,
            scale: 1.5,
          }}
        >
          <path d="M12.9999 7.82843V20H10.9999V7.82843L5.63589 13.1924L4.22168 11.7782L11.9999 4L19.778 11.7782L18.3638 13.1924L12.9999 7.82843Z" />
        </SvgIcon>
        <Typography
          className="px-4 text-center"
          variant="h2"
          component="p"
          color="var(--secondary) !important"
        >
          {"or"}
        </Typography>
        <SvgIcon
          className="will-change-transform"
          color="secondary"
          component={motion.svg}
          style={{
            y: effects.orDown.y,
            rotate: 180,
            scale: 1.5, // transform: "rotate(180deg) scale(1.5)",
          }}
        >
          <path d="M12.9999 7.82843V20H10.9999V7.82843L5.63589 13.1924L4.22168 11.7782L11.9999 4L19.778 11.7782L18.3638 13.1924L12.9999 7.82843Z" />
        </SvgIcon>
      </motion.div>
      <Typography
        className="text-center z-40 text-white absolute left-auto right-auto opacity-50 will-change-transform"
        variant="h1"
        component={motion.h2}
        fontSize={{
          xs: "28px",
          sm: "48px",
          md: "72px",
          lg: "96px",
          xl: "120px",
        }}
        sx={{
          top: {
            xs: "36px",
            sm: "48px",
            md: "48px",
            lg: "48px",
            xl: "60px",
          },
        }}
        style={{
          x: effects.chooseALearningPath.x,
          opacity: effects.chooseALearningPath.opacity,
        }}
      >
        Choose a Learning Path
      </Typography>
    </div>
  );
};
