import React from "react";
import ContentBox from "@/components/Common/ContentBox";
import { Typography, useMediaQuery } from "@mui/material";
import { SvgIcon } from "@mui/material";
import {
  motion,
  useScroll,
  useTransform,
  cubicBezier,
  useSpring,
} from "framer-motion";
import PathSelector from "@/components/Home/PathSelector";

export const LearningPathSection = ({ chooseALanguage }) => {
  const isXs = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const ease = { ease: cubicBezier(0.14, 0.4, 0.09, 0.99) };
  const springConfig = { damping: 100, mass: 10, stiffness: 100 }; //default { damping: 10, mass: 1, stiffness: 100 }

  const { scrollYProgress } = useScroll({
    target: chooseALanguage,
    offset: [isXs ? "start 80%" : "start 50%", "start start"],
  });
  const springYProgress = useSpring(scrollYProgress, springConfig);
  const effects = {
    or: {
      opacity: useTransform(
        scrollYProgress,
        [isXs ? 0.8 : 0.5, 1],
        [1, 0],
        ease
      ),
    },
    orUp: {
      y: useTransform(
        springYProgress,
        [0, isXs ? 1 : 0.5],
        ["0px", "-20px"],
        ease
      ),
    },
    orDown: {
      y: useTransform(
        springYProgress,
        [0, isXs ? 1 : 0.5],
        ["0px", isXs ? "10px" : "20px"],
        ease
      ),
    },
  };

  const draw = {
    hidden: { pathLength: 0, opacity: 0 },
    visible: (i) => {
      const delay = 1 + i * 0.5;
      return {
        pathLength: 1,
        opacity: 1,
        transition: {
          pathLength: { delay, type: "spring", duration: 2, bounce: 0 },
          opacity: { delay, duration: 0.01 },
        },
      };
    },
  };

  return (
    <ContentBox className="relative bg-textured-primary">
      {/* <motion.div
        className="flex flex-row items-center justify-center -m-8 z-50"
        style={{
          opacity: effects.or.opacity,
        }}
      >
        <SvgIcon
          component={motion.svg}
          color="secondary"
          style={{
            y: effects.orUp.y,
            scale: 1.5,
          }}
        >
          <path d="M12.9999 7.82843V20H10.9999V7.82843L5.63589 13.1924L4.22168 11.7782L11.9999 4L19.778 11.7782L18.3638 13.1924L12.9999 7.82843Z" />
        </SvgIcon>
        <Typography
          variant="h2"
          component="h2"
          color="var(--secondary) !important"
          paddingX={2}
          align="center"
        >
          {" or "}
        </Typography>
        <SvgIcon
          color="secondary"
          component={motion.svg}
          style={{
            y: effects.orDown.y,
            rotate: 180,
            scale: 1.5, // transform: "rotate(180deg) scale(1.5)",
          }}
        >
          <path d="M12.9999 7.82843V20H10.9999V7.82843L5.63589 13.1924L4.22168 11.7782L11.9999 4L19.778 11.7782L18.3638 13.1924L12.9999 7.82843Z" />
        </SvgIcon>
      </motion.div> */}
      <div className="pt-12 sm:pt-20 md:pt-28 lg:pt-36 xl:pt-44">
        <PathSelector />
      </div>
      <motion.svg
        id="logo-element-1"
        className="absolute -z-10"
        style={{
          top: "-100",
          right: -250,
          width: "500px",
          height: "500px",
        }}
        viewBox="0 0 120 120"
        xmlns="http://www.w3.org/2000/svg"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
      >
        <style>
          {`
            .logoSettings1 {
              --strokeColor: var(--secondary-light-always);
              --strokeWidth: 8;
              transform-origin: 60px 60px;
              transform: rotate(45deg);
            }
          `}
        </style>
        <motion.circle
          className="logoSettings1"
          cx="60"
          cy="60"
          r="56"
          fill="none"
          stroke="var(--strokeColor)"
          strokeWidth="var(--strokeWidth)"
          strokeLinecap="round"
          variants={draw}
          custom={0}
        />
        <motion.line
          className="logoSettings1"
          x1={22}
          y1={22}
          x2={98}
          y2={98}
          stroke="var(--strokeColor)"
          strokeWidth="var(--strokeWidth)"
          strokeLinecap="round"
          variants={draw}
          custom={1}
        />
        <motion.line
          className="logoSettings1"
          x1={98}
          y1={22}
          x2={22}
          y2={98}
          stroke="var(--strokeColor)"
          strokeWidth="var(--strokeWidth)"
          strokeLinecap="round"
          variants={draw}
          custom={2}
        />
      </motion.svg>
      {/* <motion.svg
        id="logo-element-2"
        style={{
          position: "absolute",
          top: "100",
          left: -400,
          width: "500px",
          height: "500px",
          // backgroundColor: "var(--secondary-light)",
          // borderRadius: "50%",
          zIndex: -10,
        }}
        viewBox="0 0 120 120"
        xmlns="http://www.w3.org/2000/svg"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ delayChildren: 1, staggerChildren: 2.5 }}
      >
        <style>
          {`
            .logoSettings2 {
              --strokeColor: var(--secondary-light-always);
              --strokeWidth: 8;
            }
          `}
        </style>
        <motion.circle
          className="logoSettings2"
          cx="60"
          cy="60"
          r="56"
          fill="none"
          stroke="var(--strokeColor)"
          strokeWidth="var(--strokeWidth)"
          strokeLinecap="round"
          variants={draw}
          custom={0}
        />
        <motion.line
          className="logoSettings2"
          x1={22}
          y1={22}
          x2={98}
          y2={98}
          stroke="var(--strokeColor)"
          strokeWidth="var(--strokeWidth)"
          strokeLinecap="round"
          variants={draw}
          custom={1}
        />
        <motion.line
          className="logoSettings2"
          x1={98}
          y1={22}
          x2={22}
          y2={98}
          stroke="var(--strokeColor)"
          strokeWidth="var(--strokeWidth)"
          strokeLinecap="round"
          variants={draw}
          custom={2}
        />
      </motion.svg>
      <motion.svg
        id="logo-element-3"
        style={{
          position: "absolute",
          bottom: 150,
          right: -450,
          width: "500px",
          height: "500px",
          // backgroundColor: "var(--secondary-light)",
          // borderRadius: "50%",
          zIndex: -10,
        }}
        viewBox="0 0 120 120"
        xmlns="http://www.w3.org/2000/svg"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
      >
        <style>
          {`
            .logoSettings3 {
              --strokeColor: var(--secondary-light-always);
              --strokeWidth: 8;
            }
          `}
        </style>
        <motion.circle
          className="logoSettings3"
          cx="60"
          cy="60"
          r="56"
          fill="none"
          stroke="var(--strokeColor)"
          strokeWidth="var(--strokeWidth)"
          strokeLinecap="round"
          variants={draw}
          custom={0}
        />
        <motion.line
          className="logoSettings3"
          x1={41}
          y1={10}
          x2={41}
          y2={112}
          stroke="var(--strokeColor)"
          strokeWidth="var(--strokeWidth)"
          strokeLinecap="round"
          variants={draw}
          custom={1}
        />
        <motion.line
          className="logoSettings3"
          x1={79}
          y1={112}
          x2={79}
          y2={10}
          stroke="var(--strokeColor)"
          strokeWidth="var(--strokeWidth)"
          strokeLinecap="round"
          variants={draw}
          custom={2}
        />
      </motion.svg> */}
      <motion.svg
        id="logo-element-4"
        className="absolute -z-10"
        style={{
          bottom: -100,
          left: -150,
          width: "500px",
          height: "500px",
        }}
        viewBox="0 0 120 120"
        xmlns="http://www.w3.org/2000/svg"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
      >
        <style>
          {`
            .logoSettings4 {
              --strokeColor: var(--secondary-light-always);
              --strokeWidth: 8;
              transform-origin: 60px 60px;
              transform: rotate(90deg);
            }
          `}
        </style>
        <motion.circle
          className="logoSettings4"
          cx="60"
          cy="60"
          r="56"
          fill="none"
          stroke="var(--strokeColor)"
          strokeWidth="var(--strokeWidth)"
          strokeLinecap="round"
          variants={draw}
          custom={0}
        />
        <motion.line
          className="logoSettings4"
          x1={41}
          y1={10}
          x2={41}
          y2={112}
          stroke="var(--strokeColor)"
          strokeWidth="var(--strokeWidth)"
          strokeLinecap="round"
          variants={draw}
          custom={1}
        />
        <motion.line
          className="logoSettings4"
          x1={79}
          y1={112}
          x2={79}
          y2={10}
          stroke="var(--strokeColor)"
          strokeWidth="var(--strokeWidth)"
          strokeLinecap="round"
          variants={draw}
          custom={2}
        />
      </motion.svg>
    </ContentBox>
  );
};
