import React from "react";
import { useRouter } from "next/router";
import SelectionContext from "@/utils/SelectionProvider";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Image from "next/image";
import { motion, useScroll, useTransform, cubicBezier } from "framer-motion";
import { useMediaQuery } from "@mui/material";
import AnalyticsContext from "@/utils/AnalyticsProvider";

const PathCard = ({ path, setPathSelectDialogOpen = null }) => {
  const analytics = React.useContext(AnalyticsContext);
  const router = useRouter();
  const { setPath } = React.useContext(SelectionContext);

  const handleSelectPath = () => {
    setPath(path);
    analytics.action(`Path-${path.title}-selected`);
    if (router.pathname !== "/Courses") router.push("/Courses");
    if (typeof setPathSelectDialogOpen === "function") {
      setPathSelectDialogOpen(false);
    }
  };

  const isXs = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const ease = { ease: cubicBezier(0.14, 0.4, 0.09, 0.99) };
  const cardRef = React.useRef(null);

  const effects = {
    card: {
      opacity: useTransform(
        // useSpring(
        useScroll({
          target: cardRef,
          offset: isXs ? ["end 20%", "start end"] : ["end start", "start end"],
        }).scrollYProgress,
        //   springConfig
        // ),
        [0, 0.45, 0.75, 1],
        [0, 1, 1, 0],
        ease
      ),
    },
  };

  return (
    <Card
      // key={path.id}
      ref={cardRef}
      className="flex flex-col w-full h-full overflow-hidden cursor-pointer zooming-card hoverable-card sliding-card will-change-transform"
      onClick={handleSelectPath}
      component={motion.div}
      style={{
        opacity:
          typeof setPathSelectDialogOpen !== "function"
            ? effects.card.opacity
            : null,
      }}
    >
      <div className="relative flex items-center justify-center w-full h-48 overflow-hidden cursor-pointer">
        <Image
          className="absolute z-0 object-cover object-center duration-500 cursor-pointer card-image transition-cos blur will-change-transform"
          src={path.image}
          alt=""
          fill
          placeholder="blur"
          sizes="(max-width: 600px) 90vw, 450px"
          onLoadingComplete={(image) => {
            image.classList.remove("blur");
          }}
        />
      </div>
      <CardContent>
        <Typography variant="h5" component="h3" color={"var(--primary-dark)"}>
          {path.title}
        </Typography>
        <Typography
          variant="subtitle1"
          component="p"
          color={"var(--text-dark)"}
        >
          {path.description}
        </Typography>
      </CardContent>
      {/* {typeof setPathSelectDialogOpen !== "function" && (
        <CardActions sx={{ marginTop: "auto", justifyContent: "flex-end" }}>
          <Button variant="contained" size="large" onClick={handleSelectPath}>
            Select
          </Button>
        </CardActions>
      )} */}
    </Card>
  );
};

export default PathCard;
