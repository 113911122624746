import React from "react";
import ContentBox from "@/components/Common/ContentBox";
import { Typography } from "@mui/material";

const WhyUsSection = () => (
  <ContentBox className="bg-circle-reflection" innerBoxClassName="bg-blur-2px">
    <div>
      <Typography
        variant="h3"
        component="h2"
        color="var(--secondary-always)"
        paddingY={2}
      >
        Why learn with us
      </Typography>
      <Typography
        variant="body1"
        component="p"
        color="var(--text-dark)"
        textAlign={"justify"}
        paddingBottom={2}
      >
        {
          "At Eloquence Language Center, we are dedicated to providing an exceptional language learning experience tailored to your unique goals. Our qualified instructors, interactive and engaging curriculum, and personalized approach ensure that you'll progress confidently through your chosen learning path."
        }
        <br />
        <br />
        {
          "Whether you seek to master a new language for personal enrichment, academic success, or professional advancement, our supportive environment and innovative teaching methods will empower you to achieve fluency and cultural proficiency. Join us on this rewarding journey of language discovery and unlock a world of opportunities."
        }
      </Typography>
    </div>
  </ContentBox>
);

export default WhyUsSection;
