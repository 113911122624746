import React from "react";
import { Stack } from "@mui/material";
import languages from "@/data/languages";
import { LanguageCard } from "./LanguageCard";

const LanguageSelector = ({ setLanguageSelectDialogOpen = null }) => {
  return (
    <Stack
      spacing={0}
      justifyContent="center"
      padding={0}
      direction="row" //{{ xs: "column", sm: "row" }}
      flexWrap="wrap" //{{ xs: "nowrap", sm: "wrap" }}
    >
      {languages.map((language) => (
        <LanguageCard
          key={language.value}
          language={language}
          setLanguageSelectDialogOpen={setLanguageSelectDialogOpen}
        />
      ))}
    </Stack>
  );
};

export default LanguageSelector;
