import React, { useRef } from "react";
// import dynamic from "next/dynamic";
import { NextSeo } from "next-seo";
import { LearningPathSection } from "@/components/Home/LearningPathSection";
import { HeroSection } from "@/components/Home/HeroSection";
import { SlidingWithScroll } from "@/components/Home/SlidingWithScroll";
import WhyUsSection from "@/components/Home/WhyUsSection";
import ContactForm from "@/components/Home/ContactForm";

// const WhyUsSection = dynamic(() => import("@/components/Home/WhyUsSection"), {
//   ssr: false,
// });

// const ContactForm = dynamic(() => import("@/components/Home/ContactForm"), {
//   ssr: false,
// });

export default function Home() {
  const chooseALanguage = useRef(null);

  return (
    <>
      <NextSeo
        title="ELOQUENCE | Virtual Face-to-Face Language Courses"
        titleTemplate="%s"
        description="Eloquence is a language learning platform that offers a variety of courses in different languages. We offer both group and private courses. We offer many languages including Dutch, English, German, French and Arabic. Book a free placement test now to start your language learning journey."
        canonical="https://eloq.nl/"
        openGraph={{
          images: [
            {
              url: "https://eloq.nl/img/home/og-home.jpg",
              width: 1200,
              height: 630,
              alt: "Your path to expressing eloquently. ELOQUENCE. Virtual Face-to-Face Language Courses.",
            },
          ],
        }}
      />
      {/* <div className="content-box relative z-10 mx-0 flex flex-wrap justify-center w-full overflow-hidden h-[600px] bg-black">
        <Image
          className="duration-1000 transition-cos absolute inset-0 -z-10 opacity-50"
          src={heroImage}
          alt=""
          fill
          priority
          // placeholder={"blur"}
          // quality={100}
          unoptimized
          // sizes="100vw"
          style={{
            objectFit: "cover",
            objectPosition: "center",
          }}
          // onLoadingComplete={(image) => {
          //   // remove blur class from this element
          //   image.classList.remove("blur");
          // }}
        />
      </div> */}
      <HeroSection />
      <SlidingWithScroll chooseALanguage={chooseALanguage} />
      <LearningPathSection chooseALanguage={chooseALanguage} />
      <WhyUsSection />
      <ContactForm />
    </>
  );
}
