import React from "react";
import ContentBox from "@/components/Common/ContentBox";
import { Typography } from "@mui/material";
import { Stack } from "@mui/material";
import { Button } from "@mui/material";
import { Card } from "@mui/material";
import { TextField } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { CircularProgress } from "@mui/material";
import { TaskAlt as Done, Close } from "@mui/icons-material";
import Link from "next/link";
import { IconButton } from "@mui/material";
import { useForm, Controller, useFormState } from "react-hook-form";
import { motion } from "framer-motion";
import { useAnimationControls } from "framer-motion";
import AnalyticsContext from "@/utils/AnalyticsProvider";

const validateEmail = require("email-validator");

const ContactForm = () => {
  const analytics = React.useContext(AnalyticsContext);
  const [sending, setSending] = React.useState(false);
  const [sent, setSent] = React.useState(false);

  const form = useForm({
    defaultValues: {
      name: "",
      email: "",
      subject: "",
      message: "",
    },
  });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = form;

  const clearForm = () => {
    form.reset();
    setSent(false);
  };

  const handleSend = async (data) => {
    // e.preventDefault();
    analytics.action("contact-form-submitted");
    setSending(true);
    try {
      const response = await fetch("/api/contact", {
        method: "POST",
        body: JSON.stringify({
          name: data.name,
          email: data.email,
          subject: data.subject,
          message: data.message,
        }),
      });
      setSending(false);
      if (response.ok) {
        setSent(true);
        analytics.success("contact-form-submitted");
      } else {
        analytics.error("contact-form-submitted", response.statusText);
        enqueueSnackbar(
          "An error occured while sending your message. Please try again.",
          { variant: "error" }
        );
      }
    } catch (err) {
      console.log(err);
      analytics.error("contact-form-submitted", err);
      enqueueSnackbar(
        "An error occured while sending your message. Please try again later.",
        { variant: "error" }
      );
      setSending(false);
    }
  };

  // Animation on Error

  const controls = useAnimationControls();

  // Declare a motion variant to shake the element along the x axis 3 times.
  const shake = {
    x: [0, -3, 3, -3, 3, -3, 3, 0],
    transition: { duration: 0.3 },
  };

  const { isSubmitting } = useFormState({ control });
  // Animate the element when the error changes.
  React.useEffect(() => {
    if (
      !isSubmitting &&
      (errors.name || errors.email || errors.subject || errors.message)
    ) {
      controls.start(shake);
    }
  }, [isSubmitting]);

  return (
    <ContentBox className="bg-textured-secondary-darker">
      <Typography
        variant="h3"
        component="h2"
        color="var(--text-white-dark)"
        paddingY={2}
      >
        Have Questions?
      </Typography>
      <Typography
        variant="body1"
        component="p"
        color="var(--text-white-dark)"
        textAlign={"justify"}
        paddingBottom={3}
      >
        {
          "We're here to help! If you have any inquiries, want to know more about our language courses, or need assistance, simply fill in the form below, and our team will promptly get back to you. Let's embark on this language learning journey together!"
        }
      </Typography>
      <Card>
        {!sent ? (
          <form
            id="contact-form"
            noValidate
            onSubmit={handleSubmit(handleSend)}
          >
            <Stack
              direction="column"
              spacing={2}
              paddingX={2}
              paddingY={2}
              alignItems="flex-start"
            >
              <TextField
                id="name"
                label="Name"
                autoComplete="name"
                variant="outlined"
                fullWidth // helperText="We'll never share your name."
                // value={name}
                required
                // onChange={(event) => setName(event.target.value)}
                {...register("name", { required: "Name is required." })}
                error={!!errors.name}
                helperText={errors.name?.message}
                component={motion.div}
                animate={!!errors.name ? controls : {}}
              />
              <TextField
                id="email"
                label="Email"
                autoComplete="email"
                variant="outlined"
                fullWidth
                error={!!errors.email}
                required
                {...register("email", {
                  required: "Email is required.",
                  validate: (value) =>
                    validateEmail.validate(value) ||
                    "Please enter a valid email address.",
                })}
                helperText={
                  errors.email?.message || "We'll never share your email."
                }
                component={motion.div}
                animate={!!errors.email ? controls : {}}
                // value={email}
                // onChange={(event) => setEmail(event.target.value)}
              />
              <TextField
                id="subject"
                label="Subject"
                autoComplete="subject"
                variant="outlined"
                fullWidth
                required
                // value={subject}
                // onChange={(event) => setSubject(event.target.value)}
                {...register("subject", { required: "Subject is required." })}
                error={!!errors.subject}
                helperText={errors.subject?.message}
                component={motion.div}
                animate={!!errors.subject ? controls : {}}
              />
              <TextField
                id="message"
                label="Message"
                autoComplete="message"
                variant="outlined"
                fullWidth
                multiline
                required
                rows={4}
                // value={message}
                // onChange={(event) => setMessage(event.target.value)}
                {...register("message", { required: "Message is required." })}
                error={!!errors.message}
                helperText={errors.message?.message}
                component={motion.div}
                animate={!!errors.message ? controls : {}}
              />
              <div className="w-24 h-10">
                <Button
                  className="w-full h-full"
                  variant="contained"
                  color="primary"
                  size="large"
                  type="submit"
                  disabled={sending}
                >
                  {sending ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    "Send"
                  )}
                </Button>
              </div>
            </Stack>
          </form>
        ) : (
          <div className="relative flex flex-col items-center justify-center w-full h-full p-4">
            <Done sx={{ fontSize: 64 }} color="success" />
            <IconButton
              onClick={clearForm}
              size="large"
              color="inherit"
              sx={{ position: "absolute", top: 0, right: 0 }}
            >
              <Close />
            </IconButton>
            <Typography
              variant="h4"
              component="h2"
              color="success.main"
              paddingY={2}
            >
              Hang on tight!
            </Typography>
            <Typography
              variant="body1"
              component="h3"
              color="var(--text-dark)"
              textAlign={"center"}
              paddingBottom={3}
            >
              {
                "We will get back to you as soon as possible. In the meantime, you can check out our "
              }
              <Link href="/Courses#faq" scroll={false}>
                FAQ page
              </Link>
              {" for answers to some of the most common questions."}
            </Typography>
          </div>
        )}
      </Card>
    </ContentBox>
  );
};

export default ContactForm;
