import React from "react";
import { Grid } from "@mui/material";
import PathCard from "@/components/Home/PathCard";
import paths from "@/data/paths";

const PathSelector = ({ setPathSelectDialogOpen = null }) => {
  return (
    <Grid container spacing={0} columns={{ xs: 3, sm: 6, md: 12, lg: 12 }}>
      {paths.map((path) => (
        <Grid item key={path.id} xs={3} padding={1}>
          <PathCard
            path={path}
            setPathSelectDialogOpen={setPathSelectDialogOpen}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default PathSelector;
