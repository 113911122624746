import React from "react";
import { useRouter } from "next/router";
import SelectionContext from "@/utils/SelectionProvider";
import { useMediaQuery } from "@mui/material";
import { Button } from "@mui/material";
import Typography from "@mui/material/Typography";
import Image from "next/image";
import { motion, useScroll, useTransform } from "framer-motion";
import AnalyticsContext from "@/utils/AnalyticsProvider";

export const LanguageCard = ({ language, setLanguageSelectDialogOpen }) => {
  const analytics = React.useContext(AnalyticsContext);
  const isXs = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  var rotation = Math.random() * 35 + 20;
  if (Math.random() > 0.5) rotation *= -1;
  const cardRef = React.useRef(null);

  const effects = {
    card: {
      y: useTransform(
        useScroll({
          target: cardRef,
          offset: isXs ? ["end 60%", "end 20%"] : ["end 350px", "end 160px"],
        }).scrollYProgress,
        [0, 1],
        ["0px", "-60px"]
      ),
      m: useTransform(
        useScroll({
          target: cardRef,
          offset: isXs ? ["end 60%", "end 20%"] : ["end 350px", "end 60px"],
        }).scrollYProgress,
        [0, 1],
        isXs ? ["0.2rem", "0.2rem"] : ["1rem", "1rem"]
      ),
      rotation: useTransform(
        useScroll({
          target: cardRef,
          offset: isXs ? ["end 60%", "end 20%"] : ["end 400px", "end 160px"],
        }).scrollYProgress,
        [0, 0.4, 1],
        ["0deg", `${rotation / 4}deg`, `${rotation}deg`]
      ),
      borderRadius: useTransform(
        useScroll({
          target: cardRef,
          offset: isXs ? ["end 70%", "end 20%"] : ["end 550px", "end 160px"],
        }).scrollYProgress,
        [0, 1],
        ["1.5rem", isXs ? "4.5rem" : "9rem"]
      ),
      marginFlagLeft: useTransform(
        useScroll({
          target: cardRef,
          offset: isXs ? ["end 70%", "end 20%"] : ["end 550px", "end 160px"],
        }).scrollYProgress,
        [0, 1],
        ["0px", isXs ? "0px" : "50px"]
      ),
      marginFlagBottom: useTransform(
        useScroll({
          target: cardRef,
          offset: isXs ? ["end 70%", "end 20%"] : ["end 550px", "end 160px"],
        }).scrollYProgress,
        [0, 1],
        ["0px", isXs ? "45px" : "50px"]
      ),
    },
  };

  const { setLanguage } = React.useContext(SelectionContext);
  const router = useRouter();

  return (
    <motion.div
      className="language-card flex align-center justify-center rounded-3xl p-0.5 sm:p-1.5 m-0.5 sm:m-4 will-change-transform"
      ref={cardRef}
      style={{
        background:
          "linear-gradient(45deg, var(--secondary-always) 30%, var(--accent1-always) 90%)",
        y:
          typeof setLanguageSelectDialogOpen !== "function"
            ? effects.card.y
            : null,
        margin:
          typeof setLanguageSelectDialogOpen !== "function"
            ? effects.card.m
            : null,
        rotate:
          typeof setLanguageSelectDialogOpen !== "function"
            ? effects.card.rotation
            : null,
        borderRadius:
          typeof setLanguageSelectDialogOpen !== "function"
            ? effects.card.borderRadius
            : null,
      }}
      whileHover={
        !isXs &&
        typeof setLanguageSelectDialogOpen !== "function" && {
          scale: 1.07,
          y: -10,
          rotate: "-2deg",
          transition: {
            type: "spring",
            stiffness: 500,
            damping: 10,
            mass: 2,
          },
        }
      }
    >
      <Button
        className="relative overflow-hidden rounded-3xl"
        variant="text"
        onClick={() => {
          setLanguage(language);
          analytics.action(`Language-${language.label}-selected`);
          if (router.pathname !== "/Courses") router.push("/Courses");
          if (typeof setLanguageSelectDialogOpen === "function")
            setLanguageSelectDialogOpen(false);
        }}
        component={motion.button}
        sx={{
          minWidth: { xs: "9rem", sm: "12rem" },
          minHeight: { xs: "9rem", sm: "12rem" },
          width: { xs: "9rem", sm: "16vw" },
          height: { xs: "9rem", sm: "16vw" },
          maxWidth: { xs: "9rem", sm: "20rem" },
          maxHeight: { xs: "9rem", sm: "20rem" },
        }}
        style={{
          borderRadius:
            typeof setLanguageSelectDialogOpen !== "function"
              ? effects.card.borderRadius
              : null,
        }}
      >
        <div className="absolute inset-0 z-0 flex items-center justify-center image-gradient-container">
          <Image
            className="object-cover object-center duration-500 transition-cos blur"
            src={language.image}
            alt={language.label}
            fill
            placeholder="blur"
            priority
            sizes="(max-width: 600px) 225px, 450px"
            onLoadingComplete={(image) => {
              image.classList.remove("blur");
            }}
          />
          <div className="image-gradient-overlay" />
        </div>
        <motion.div
          className="absolute bottom-0 left-0 z-10 flex items-center flex-row p-2.5 pointer-events-none"
          style={{
            marginLeft:
              typeof setLanguageSelectDialogOpen !== "function"
                ? effects.card.marginFlagLeft
                : null,
            marginBottom:
              typeof setLanguageSelectDialogOpen !== "function"
                ? effects.card.marginFlagBottom
                : null,
          }}
        >
          <Image
            className="drop-shadow-strong"
            src={language.flag}
            alt=""
            width={isXs ? 40 : 60}
            height={isXs ? 40 : 60}
          />
          <Typography
            className="p-2 text-white normal-case drop-shadow-strong"
            variant="h5"
            component="h3"
          >
            {language.label}
          </Typography>
        </motion.div>
      </Button>
    </motion.div>
  );
};
