import React from "react";
import { Typography, useMediaQuery } from "@mui/material";
import LanguageSelector from "@/components/Home/LanguageSelector";
import Image from "next/image";

import heroImage from "@/public/img/home/main-h.webp";

export const HeroSection = () => {
  const isXs = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <section>
      <div className="content-box relative z-10 mx-0 flex flex-wrap justify-center w-full overflow-hidden bg-gray-950">
        <Image
          className="duration-1000 transition-cos absolute inset-0 -z-10 opacity-50 object-cover object-center blur will-change-auto"
          src={heroImage}
          alt=""
          fill
          priority
          placeholder={"blur"}
          // quality={100}
          sizes="100vw"
          onLoadingComplete={(image) => {
            // remove blur class from this element
            image.classList.remove("blur");
          }}
        />
        <div className={`w-full relative z-20 p-4`}>
          <Typography
            variant="h2"
            component="h1"
            paddingTop={2}
            align="center"
            color="rgba(255, 255, 255, 0.87)"
          >
            {"Your path to expressing eloquently."}
          </Typography>
          <Typography
            variant="h4"
            component="h2"
            color="rgba(255, 255, 255, 0.87)"
            paddingTop={2}
            align="center"
          >
            {
              "Immerse yourself in a world of languages and broaden your horizons."
            }
          </Typography>
          <div className="flex items-center justify-center pt-4">
            <LanguageSelector />
          </div>
          <div className={`w-full ${isXs ? "h-8" : "h-16"}`} />
        </div>
      </div>
    </section>
  );
};
